

































































































import { Component, Vue } from 'vue-property-decorator';
import BaseBankIcon from '@/components/BaseBankIcon.vue';
import ProfileConnector from '@/connector/Profile.vue';

@Component({
  components: {
    BaseBankIcon,
    ProfileConnector,
  },
})
export default class PlayerInfoPage extends Vue {
}
