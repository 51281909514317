












import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component
export default class BaseBankIcon extends Vue {
  @Prop({ type: String, required: true }) bank!: string;

  @Prop({ type: String }) height!: string;

  get bankBackground() {
    if (this.bank === 'kbank') return '#138f2d';
    if (this.bank === 'scb') return '#4e2e7f';
    if (this.bank === 'ktb') return '#1ba5e1';
    if (this.bank === 'bbl') return '#1e4598';
    if (this.bank === 'ttb') return 'white';
    if (this.bank === 'uob') return '#0b3979';
    if (this.bank === 'bay') return '#fec43b';
    if (this.bank === 'gsb') return '#eb198d';
    if (this.bank === 'baac') return '#4b9b1d';
    return 'white';
  }
}
